
import api from '@/utilities/api'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { headers, items } from '@/pages/vehicles/helpers/index'
import AddEditVehicleDialog from '@/pages/vehicles/AddEditVehicleDialog'

@Component({
  components: {
    'c-add-edit-vehicle-dialog': AddEditVehicleDialog,
  },
})
export default class Vehicles extends Vue {
  // data // ***
  showAddEditVehicleDialog = false
  isEditMode = false
  itemUnderEdit: Record<string, any> = {}
  warningPromptOpen = false
  confirmDeleteVehiclePrompt = ''

  // computed // ***
  get actionHeader() {
    const { actions } = this
    return { vueComponent: { name: 'c-table-actions', props: { actions } } }
  }
  get actions() {
    return [
      {
        callback: (item: Record<string, any>) => this.onEdit(item),
        color: 'accent',
        icon: 'edit',
        value: 'edit',
      },
      {
        callback: (item: Record<string, any>) => this.onDelete(item),
        color: 'error',
        icon: 'delete',
        value: 'delete',
      },
    ]
  }
  get deleting(): boolean {
    const { delete: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'POST', url })
  }
  get vehicles() {
    return this.$store.getters['suppliers/vehicles']
  }
  get vehicleTypes() {
    return this.$store.getters['suppliers/vehicleTypes']
    //return sortAlphabeticallyByKey(types, 'description')
  }
  get features(): Record<string, any> {
    return this.$auth.features
  }
  get fetchingVehicles(): boolean {
    const { fetch: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'POST', url })
  }
  get fetchingVehicleTypes(): boolean {
    const { types: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
  }
  get headers() {
    return headers()
  }
  get items() {
    return items(this)
  }
  get loading(): boolean {
    const { fetchingVehicles } = this
    return fetchingVehicles
  }
  get organisationId(): string {
    return String(this.supplier?.id)
  }
  get params() {
    const { organisationId } = this
    return { organisationId, ReturnAllResults: true }
  }
  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get uploading(): boolean {
    const { upload: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'POST', url })
  }
  get urls(): Record<string, string> {
    return {
      fetch: `organisation/vehicles/${this.organisationId}`,
      vehicleTypes: 'referencedata/vehicletypes',
      psvarStatuses: 'referencedata/psvarstatuses',
    }
  }
  get canSearch() {
    return this.$store.getters['suppliers/suppliers'].length > 1
  }

  // created // ***
  created() {
    this.redirectIfDisabled()
    this.onCreated()
  }
  async onCreated() {
    this.fetch()
    this.fetchVehicleTypes()
    this.fetchPsvarStatuses()
  }

  // watch // ***
  @Watch('features')
  onFeaturesChanged(): void {
    this.redirectIfDisabled()
  }
  @Watch('supplier')
  onSupplierChanged(n: Record<string, any> | undefined, o: Record<string, any> | undefined): void {
    if (n && o?.id !== n.id) this.fetch()
  }

  // methods // ***
  actionIsLoading(item: Record<string, any>, action: Record<string, any>): boolean {
    return !(item && action) // TODO: set this up once the download/delete endpoints are in
  }
  async fetch() {
    const { params: data, urls } = this
    const { fetch: url } = urls
    const options = { data, json: true, method: 'GET', url }
    await this.$store.dispatch('suppliers/fetchVehicles', options)
  }
  async fetchVehicleTypes() {
    const { params: data, urls } = this
    const { vehicleTypes: url } = urls
    const options = { data, json: true, method: 'GET', url }
    await this.$store.dispatch('suppliers/fetchVehicleTypes', options)
  }
  async fetchPsvarStatuses() {
    const { params: data, urls } = this
    const { psvarStatuses: url } = urls
    const options = { data, json: true, method: 'GET', url }
    await this.$store.dispatch('suppliers/fetchPsvarStatuses', options)
  }
  getActionTitle(item: Record<string, any>, action: Record<string, any>): string {
    if (action.value === 'delete') return 'Delete'
    else if (action.value === 'edit') return 'Edit'
    else return ''
  }
  onAddNew() {
    this.isEditMode = false
    this.showAddEditVehicleDialog = true
  }
  onEdit(i: Record<string, any>) {
    this.isEditMode = true
    this.itemUnderEdit = i
    this.showAddEditVehicleDialog = true
  }
  onDelete(i: Record<string, any>) {
    this.itemUnderEdit = i
    this.confirmDeleteVehiclePrompt = 'Are you sure you want to delete vehicle ' + i.vrm + '?'
    this.warningPromptOpen = true
  }
  onCancel() {
    this.warningPromptOpen = false
  }
  onCancelNewVehicle() {
    this.showAddEditVehicleDialog = false
  }
  onSavedNewVehicle() {
    this.showAddEditVehicleDialog = false
    this.fetch()
  }
  async onConfirm() {
    const url = `organisation/deletevehicle/${this.itemUnderEdit.organisationVehicleId}`
    const options = { method: 'DELETE' }
    await api(url, options, {}).then(() => {
      this.fetch()
      this.warningPromptOpen = false
      this.itemUnderEdit = {}
    })
    this.warningPromptOpen = false
  }
  openAddEditDialog(i: Record<string, any>) {
    this.itemUnderEdit = i
    this.showAddEditVehicleDialog = true
  }
  onSearch(): void {
    this.fetch()
  }
  onUpload(): void {
    this.fetch()
  }
  redirectIfDisabled() {
    if (this.$store.getters['core/featureDisabled']('vehicleManagement'))
      this.$router.push('/errors/404')
  }

  // watches
  @Watch('value')
  onValueChanged(v: Record<string, any>) {
    if (!v) {
      this.itemUnderEdit = {}
    }
  }
}
