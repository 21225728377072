import i18n from '@/plugins/i18n'

const headers = (): Record<string, any>[] => [
  { text: i18n.t('cmac.vehicleRegistration'), value: 'vrm' },
  { text: i18n.t('common.description'), value: 'vehicleTypeDescription' },
  { text: i18n.t('common.notes'), value: 'notes' },
  { text: i18n.t('cmac.psvarStatus'), value: 'psvarStatusDescription' },
]

export default headers
