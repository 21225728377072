
import api from '@/utilities/api'
import { cloneDeep } from 'lodash'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { isRequired } from '@/utilities/validations'
import { title } from '@/utilities/filters'

@Component
export default class AddEditVehicleDialog extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  value!: boolean
  @Prop({ type: Object })
  vehicle!: Record<string, any>
  @Prop({ type: String })
  organisationId!: ''
  @Prop({ type: Boolean })
  editMode!: boolean

  // data // ***
  item: Record<string, any> | null = null

  // watches
  @Watch('value')
  onValueChanged(v: Record<string, any>) {
    if (v && !this.editMode) {
      this.item = {
        organisationVehicleId: 0,
        vrm: null,
        notes: null,
        psvarStatus: 1,
      }
    }
  }
  @Watch('vehicle')
  onVehicleChanged(v: Record<string, any>) {
    this.item = cloneDeep(v)
  }

  // computed // ***
  get dialogTitle() {
    return this.editMode ? this.$t('common.editVehicle') : this.$t('common.addVehicle')
  }
  get vehicleTypes() {
    return this.$store.getters['suppliers/vehicleTypes']
  }
  get psvarStatuses() {
    return this.$store.getters['suppliers/psvarStatuses']
  }
  get rules(): Record<string, any> {
    return {
      vrm: [isRequired],
      vehicleType: [isRequired],
      psvarStatus: [isRequired],
    }
  }

  // methods // ***
  async save() {
    this.resetValidation()
    const valid = await this.validateForm()
    if (!valid) return
    if (!this.item) return
    let payload = {
      vrm: this.item.vrm,
      vehicleTypeId: this.item.vehicleTypeId,
      notes: this.item.notes,
      organisationVehicleId: this.item.organisationVehicleId,
      psvarStatus: this.item.psvarStatus,
      organisationId: this.organisationId,
    }
    const url = `organisation/createorupdatevehicle`
    const options = { method: 'POST' }
    const axiosOptions = { data: payload }
    await api(url, options, axiosOptions).then(() => {
      this.resetValidation()
      this.$emit('saved')
    })
  }
  cancel() {
    this.$emit('cancel')
  }
  resetValidation() {
    this.$store.commit('core/resetErrors')
  }
  getTitle(s: any) {
    return title(s)
  }
  async validateForm(): Promise<boolean> {
    const refs: Record<string, any> = this.$refs
    return await refs.form.validate()
  }
}
